import { ReactNode } from 'react';
import { HeaderGlobal } from '@/components/header/HeaderGlobal';
import { ThemeProvider } from '@/hooks/useTheme';
import { IconSpritesheet } from '@/components/icon/IconSpritesheet';
import clsx from 'clsx';
import { ToastManager } from '@/components/toasts/ToastManager';
import { Head, router } from '@inertiajs/react';
import { HeaderPublic } from '@/components/header/HeaderPublic';
import { useDownloadReadyToast } from '@/hooks/useDownloadReadyToast';

interface Props {
  children: ReactNode;
  className?: string;
  area?: string;
  title?: string;
  transparent?: boolean;
  footerTheme?: 'dark' | 'light';
  isPublicPage?: boolean;
}

export function Authenticated({
  children,
  className = '',
  area,
  title,
  transparent = true,
  footerTheme = 'light',
  isPublicPage = false
}: Props) {
  useDownloadReadyToast();

  // eslint-disable-next-line
  // @ts-ignore
  if (!router.page) {
    console.warn(
      'Inertia router is missing its `page` property, delaying rendering the rest of the page!'
    );
    return null;
  }

  return (
    <div className={clsx('flex h-full flex-col', className)}>
      <Head title={title} />

      <ThemeProvider theme="dark">
        {isPublicPage ? <HeaderPublic /> : <HeaderGlobal area={area} />}
        <div className="relative flex h-full flex-1 flex-col overflow-hidden">
          <ThemeProvider theme={footerTheme}>
            <div
              className={clsx(
                transparent ? 'bg-transparent' : 'bg-white',
                'flex flex-1 flex-col h-full'
              )}
            >
              <div className="flex-grow flex flex-1 h-full flex-col">{children}</div>
            </div>
          </ThemeProvider>
        </div>
      </ThemeProvider>
      <ToastManager />
      <IconSpritesheet />
    </div>
  );
}
