import clsx from 'clsx';
import React from 'react';

import { Icon } from '../icon/Icon';
import { BodyTextSm } from '../typography/body/BodyText';
import { H4 } from '../typography/headings/Heading';
import { ToastNotification as NotificationInterface } from '@/@types/ToastNotification';
import { withTheme } from '../theme/withTheme';
import { DefaultButton } from '@/components/button/DefaultButton';
import { IconOnlyButton } from '../button/IconOnlyButton';

interface Props extends NotificationInterface {
  onDark?: boolean;
  onClear: (visible: boolean) => void;
  icon: boolean;
  buttonLink?: string;
  buttonLinkIsExternal?: boolean;
  buttonLabel?: string;
}

export const Notification = withTheme<Props>(
  ({
    onDark = false,
    icon,
    title,
    message,
    type,
    onClear,
    buttonLink,
    buttonLinkIsExternal,
    buttonLabel
  }: Props) => {
    const themeVariant = onDark ? 'dark' : 'light';

    function typeIcon(type: string) {
      return {
        danger: 'high-priority',
        success: 'check',
        caution: 'high-priority',
        default: 'info'
      }[type];
    }

    return (
      <div
        theme={themeVariant}
        className={clsx(
          type === 'high-impact' && 'bg-amethyst text-violet-80 dk:bg-emerald dk:text-turquoise-20',
          type === 'danger' && 'bg-red-70 text-red-20 dk:bg-red-30 dk:text-red-80',
          type === 'info' && 'bg-blue-60 text-blue-20 dk:bg-blue-30 dk:text-blue-80',
          type === 'success' && 'bg-green-90 text-green-20 dk:bg-green-40 dk:text-green-90',
          type === 'caution' && 'bg-orange-80 text-orange-30 dk:bg-orange-70 dk:text-orange-20',
          'flex items-stretch overflow-hidden rounded'
        )}
      >
        {icon ? (
          <div
            className={clsx(
              type === 'high-impact' && 'bg-violet-30 dk:bg-turquoise-90',
              type === 'danger' && 'bg-red-60 dk:bg-red-40',
              type === 'info' && 'bg-blue-50 dk:bg-blue-40',
              type === 'success' && 'bg-green-80 dk:bg-green-50',
              type === 'caution' && 'bg-orange-70 dk:bg-orange-80',
              'flex items-center p-2'
            )}
          >
            <Icon icon={typeIcon(type) ?? 'info'} className="h-6 w-6" />
          </div>
        ) : null}
        <div className="flex-1 p-4">
          {title ? (
            <H4
              className={clsx(
                type === 'high-impact' && 'text-violet-90 dk:text-turquoise-10',
                type === 'danger' && 'text-red-10 dk:text-red-90',
                type === 'info' && 'text-blue-10 dk:text-blue-100',
                type === 'success' && 'text-green-10 dk:text-green-100',
                type === 'caution' && 'text-orange-20 dk:text-orange-10'
              )}
            >
              {title}
            </H4>
          ) : null}
          {message ? <BodyTextSm className={clsx(title && 'text-xs')}>{message}</BodyTextSm> : null}
          {buttonLink ? (
            <DefaultButton
              className={clsx(
                type === 'danger' && 'bg-red-30 dk:bg-red-20',
                type === 'high-impact' && 'bg-violet-10 dk:bg-turquoise-30',
                type === 'info' && 'bg-blue-30 dk:bg-blue-20',
                type === 'success' && 'bg-green-30 dk:bg-green-20',
                type === 'caution' && 'bg-orange-40 dk:bg-orange-30',
                'mt-2'
              )}
              rounding="square"
              size="xs"
              href={buttonLink}
              label={buttonLabel ?? 'Visit Link'}
              external={buttonLinkIsExternal}
            />
          ) : null}
        </div>
        <div className="p-2">
          <IconOnlyButton
            theme={type === 'high-impact' ? (onDark ? 'light' : 'dark') : themeVariant}
            onClick={() => onClear(false)}
            className="mb-auto"
            size="xs"
            rounding="square"
            variant="default-borderless"
            type="button"
            icon="cross"
          />
        </div>
      </div>
    );
  }
);
